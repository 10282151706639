
<div >
  <article class="home">
   <section id="principal">
    <div class="banner">
      <img [src]="safeSrcImgRegulamento" class="mt-4">
    </div>
  </section>

  <div class="regulamento container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <embed  [src]="safeSrc" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0" scrolling="no" type='application/pdf'/>
        </div>
    </div>
  </div>
</article>
</div>
