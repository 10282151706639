import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './components/error/error.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { RulesComponent } from './components/rules/rules.component';
import { MainComponent } from './modules/main/main.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {
    path: ':key',
    component: MainComponent,
    children:[
      {
        path: '',
        component:HomeComponent
      },
      {
        path: 'rules',
        component:RulesComponent
      }
    ]
  },
  {
    path: ':key/404',
    component: NotFoundComponent
  },
  {
    path: ':key/500',
    component: ErrorComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{anchorScrolling: 'enabled',scrollOffset: [0, 50],preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
