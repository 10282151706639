import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { IServiceService } from "../interfaces/iservice.service";

@Injectable({
  providedIn: 'root'
})
export abstract  class DataSourceService<T>  {

  protected abstract dataService: IServiceService<T>;

  constructor( public router: Router) {

  }

  protected abstract key:string;

  public keySite : string = '';
  public  model! :T ;

  protected abstract listDataSorce: BehaviorSubject<T[]>;
  protected abstract objectDataSorce: BehaviorSubject<T>;

  public getObjectData():Observable<T>{

    var result = sessionStorage.getItem(this.key);

   if (result !== null && result  !== undefined && result.length > 5)
       this.objectDataSorce.next(JSON.parse(result) as T) ;
   else
       this.getDataObject();

    return this.objectDataSorce.asObservable();
}


  private getDataObject():void{

    let dataResult:T;

    var observableResult = this.dataService.searchObjectKey(this.keySite);

        observableResult.subscribe({
        next: (result)=>{
          dataResult =result.result ? result.data  as T : new Object as T;
          if (this.key == null ||this.key == ''|| this.key == undefined ||result == null || !result.result || result.data == null){
             this.router.navigate([`${this.keySite}/404`]);
            return;
          }
          sessionStorage.setItem(this.key,JSON.stringify(dataResult));
          this.objectDataSorce.next(dataResult as T);
        },
        error:(fail: { message: any; status: any; statusText: any; })=>{
          throw new Error(fail.message ?? `${fail.status}:${fail.statusText}`);
        }
    });

    this.listDataSorce.asObservable();
  }

}
