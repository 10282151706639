import { Home, MainHome } from '@/model/conteudo/home';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteHomeRuleDataSourceService } from '@services/datasource/site-home-rule-datasource.service ';
import { SiteMainHomeDataSourceService } from '@services/datasource/site-main-home-datasource.service ';
import { Observable } from 'rxjs';
import { HeaderComponent } from './header/header.component';



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {

 data:Home = new Home ();

 @ViewChild(HeaderComponent, { static: true })
  header!: HeaderComponent;

  constructor(private siteMainHomeDataSourceService:SiteMainHomeDataSourceService,
              public ruleDataSource:SiteHomeRuleDataSourceService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {

    const key = String(this.route.snapshot.paramMap.get("key")).replace("#principal","").replace("#comoParticipar","").replace("#contatos","").replace("#sorteios","");

      this.carregarHome(key).subscribe({
        next:(result)=>{
          this.data = result as Home;
          this.carregarHeader();
          this.carregarRegulamento(key);

        },
        error:(fail)=>{
           this.router.navigate([`${key}/500`]);
        }
      });

  }

  carregarRegulamento(isKey:string){

    this.ruleDataSource.keySite = isKey;
    this.ruleDataSource.key = `${isKey}_rule`;

    return this.ruleDataSource.getObjectData();
}

  carregarHome(isKey:string):Observable <MainHome> {

    this.siteMainHomeDataSourceService.keySite = isKey;
    this.siteMainHomeDataSourceService.key = `${isKey}_home`;
    return this.siteMainHomeDataSourceService.getObjectData();

  }

  carregarHeader(){
    setTimeout(() => {
      this.header.carregar();
    }, 500);
  }

}
