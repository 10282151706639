import { Home, RuleHome } from '@/model/conteudo/home';
import { Rule } from '@/model/conteudo/rule';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteRuleDataSourceService } from '@services/datasource/site-rule-datasource.service';
import { SiteRuleHomeDataSourceService } from '@services/datasource/site-rule-home-datasource.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.scss']
})
export class RulesComponent implements OnInit,AfterViewInit  {

  public data:RuleHome = new RuleHome();
  public rule:Rule = new Rule();

  public safeSrc: SafeResourceUrl = '';
  public safeSrcImgRegulamento: SafeUrl = '';

  constructor(public siteRuleDataSourceService:SiteRuleDataSourceService,
              public siteHomeDataSourceService:SiteRuleHomeDataSourceService,
              public route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private router: Router ) {
  }


  ngOnInit(): void {

    const key = String(this.route.parent?.snapshot.paramMap.get('key')).replace("#principal","").replace("#comoParticipar","").replace("#contatos","").replace("#sorteios","");


    this.carregarDataHome(key).subscribe({
      next:(result)=>{

        this.data = result;

        //Imagem Regulamento
       this.safeSrcImgRegulamento = this.sanitizer.bypassSecurityTrustUrl(this.data.imgRegulamento);

       this.carregarDataCorSite();
       this.carregarDataCorFonteSite();

        this.carregarRule("rule",key).subscribe({
          next:(result)=>{
              this.rule = result;
              this.carregarArquivoImagemRegulamentoSite();
           }});

      }});

  }

  ngAfterViewInit() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }


  carregarArquivoImagemRegulamentoSite(){

    //Arquivo Regulamento
    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.rule.arquivoRegulamento);

  }

  carregarDataHome(isKey:string):Observable <Home> {

    this.siteHomeDataSourceService.keySite = isKey;
    this.siteHomeDataSourceService.key = `${isKey}_home`;

    return this.siteHomeDataSourceService.getObjectData();

  }

  carregarRule(isFilterKey:string, isKey:string):Observable <Rule> {

    this.siteRuleDataSourceService.keySite = isKey;
    this.siteRuleDataSourceService.key = `${isKey}_rule`;

    return this.siteRuleDataSourceService.getObjectData();
  }

  carregarDataCorSite(){
    // Cor do Menu
    var colorMenu = document.getElementsByName("colorMenu");
    colorMenu[0].style.background = this.data.colorSite;
  }

  carregarDataCorFonteSite(){
    // Cor fonte Menu
    let colorMenuFontePaginaInicial = document.getElementsByName("colorMenuFontePaginaInicial");
    colorMenuFontePaginaInicial[0].style.color = this.data.colorFonte;

    var colorMenuFonteComoParticipar = document.getElementsByName("colorMenuFonteComoParticipar");
    colorMenuFonteComoParticipar[0].style.color = this.data.colorFonte;

    let colorMenuFontePaginaInicialRegulamento = document.getElementsByName("colorMenuFonteRegulamento");
    colorMenuFontePaginaInicialRegulamento[0].style.color = this.data.colorFonte;

    let colorMenuFonteContato = document.getElementsByName("colorMenuFonteContato");
    colorMenuFonteContato[0].style.color = this.data.colorFonte;

    let colorMenuFonteSorteio = document.getElementsByName("colorMenuFonteSorteio");
    colorMenuFonteSorteio[0].style.color = this.data.colorFonte;

    let colorMenuFonteSegurancaPrivacidade = document.getElementsByName("colorMenuFonteSegurancaPrivacidade");
    colorMenuFonteSegurancaPrivacidade[0].style.color = this.data.colorFonte;
  }

}
