import { MainHome } from "@/model/conteudo/home";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SiteHomeService } from "../site-home.service";
import { DataSourceService } from "./datasource.service";

@Injectable({
  providedIn: 'root'
})

export class SiteMainHomeDataSourceService extends DataSourceService<MainHome> {

  protected objectDataSorce: BehaviorSubject<MainHome>;

  protected dataService: SiteHomeService;

  public key:string = '';

  protected listDataSorce: BehaviorSubject<MainHome[]>;

  constructor(siteRuleService:SiteHomeService, public override router: Router) {
    super(router);
    this.dataService = siteRuleService;
    this.listDataSorce = new BehaviorSubject<MainHome[]>(new Array);
    this.objectDataSorce = new BehaviorSubject<MainHome>(new MainHome);
    this.key=  `${this.keySite}_home` ;

   }
}
