import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Rule } from '../model/conteudo/rule';
import { ResponseResult } from '../model/responseresult';
import { IServiceService } from './interfaces/iservice.service';

@Injectable({
  providedIn: 'root'
})

export class SiteRuleService implements IServiceService<Rule>  {

private apiUrl = `${environment.urlServiceConhecimentoPremiado}/site`;

private httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
}

constructor( private http: HttpClient){

}

 public searchObjectKey(key: string): Observable<ResponseResult<Rule>> {
  return this.http
  .get<ResponseResult<Rule>>(`${this.apiUrl}/rule?chave=${key}`,this.httpOptions);
  }

}
