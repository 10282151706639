import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Home } from '../model/conteudo/home';
import { ResponseResult } from '../model/responseresult';
import { IServiceService } from './interfaces/iservice.service';

@Injectable({
  providedIn: 'root'
})

export class SiteHomeService implements IServiceService<Home>  {

private apiUrl = `${environment.urlServiceConhecimentoPremiado}/site`;

private httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
}

constructor( private http: HttpClient){

}

 public searchObjectKey(key: string): Observable<ResponseResult<Home>> {
  return this.http
  .get<ResponseResult<Home>>(`${this.apiUrl}/home?chave=${key}`,this.httpOptions);
  }

}
