import { Home } from '@/model/conteudo/home';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteHomeDataSourceService } from '@services/datasource/site-home-datasource.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit  {

  public innerWidth: any;

  public home:Home = new Home();

  constructor(private siteHomeDataSourceService:SiteHomeDataSourceService,
              public route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private router: Router
         ) {
  }

  ngOnInit(): void {

    const key = String(this.route.snapshot.paramMap.get("key")).replace("#principal","").replace("#comoParticipar","").replace("#contatos","").replace("#sorteios","");

      this.carregarHome(key).subscribe({
        next:(result)=>{
          if (result == null || result.chaveParceiro == null || result.chaveParceiro == '' || result.chaveParceiro == undefined)
            return;

                  this.home = result;
                  this.innerWidth = window.innerWidth;
                  this.carregarImagemSite();
                  this.carregarCorSite();
                  this.carregarCorFonteSite();

        },
        error:(fail)=>{
           this.router.navigate([`${key}/500`]);
        }
    });
  }

@HostListener('window:resize', ['$event'])
onResize() {
  this.innerWidth = window.innerWidth;
  this.carregarCorFonteSite();
}

  carregarHome(isKey:string):Observable <Home> {

    this.siteHomeDataSourceService.keySite = isKey;
    this.siteHomeDataSourceService.key = `${isKey}_home`;

    return this.siteHomeDataSourceService.getObjectData();

  }

  carregarImagemSite(){

    //Imagem Esquerda
    this.home.imgLeft = this.home.imgLeft;

    //Imagem Direita
    this.home.imgRight = this.home.imgRight;

    //Logo
    this.home.logo = this.home.logo;

    this.home.imgRegulamento = this.home.imgRegulamento;

  }

  carregarCorSite(){

    // Cor do Site Menu
    let colorMenu = document.getElementsByName("colorMenu");
    colorMenu[0].style.background = this.home.colorSite;

    let colorMenuMobile = document.getElementsByName("colorMenuMobile");
    colorMenuMobile[0].style.background = this.home.colorSite;

    // Cor do Site Home
    let colorHome = document.getElementsByName("colorHome");

   // if(colorHome !== undefined && colorHome !== null && colorHome.length > 0 && colorHome[0] != null && colorHome[0].style !== undefined && colorHome[0].style.background !== undefined)
       colorHome[0].style.background = this.home.colorSite;
  }

  carregarCorFonteSite(){

    // Cor fonte Menu
    let colorMenuFontePaginaInicial = document.getElementsByName("colorMenuFontePaginaInicial");
    colorMenuFontePaginaInicial[0].style.color = this.home.colorFonte;

    let colorMenuFonteComoParticipar = document.getElementsByName("colorMenuFonteComoParticipar");
    colorMenuFonteComoParticipar[0].style.color = this.home.colorFonte;

    let colorMenuFontePaginaInicialRegulamento = document.getElementsByName("colorMenuFonteRegulamento");
    colorMenuFontePaginaInicialRegulamento[0].style.color = this.home.colorFonte;

    let colorMenuFonteContato = document.getElementsByName("colorMenuFonteContato");
    colorMenuFonteContato[0].style.color = this.home.colorFonte;

    let colorMenuFonteSorteio = document.getElementsByName("colorMenuFonteSorteio");
    colorMenuFonteSorteio[0].style.color = this.home.colorFonte;

    let colorMenuFonteSegurancaPrivacidade = document.getElementsByName("colorMenuFonteSegurancaPrivacidade");
    colorMenuFonteSegurancaPrivacidade[0].style.color = this.home.colorFonte;

    // Cor fonte da Home
    let colorHome = document.getElementsByName("colorHome");
   // if(colorHome !== undefined && colorHome !== null && colorHome.length > 0 && colorHome[0] != null && colorHome[0].style !== undefined && colorHome[0].style.color !== undefined)
       colorHome[0].style.color = this.home.colorFonte;

    // Tablet e Moblie
    if (this.innerWidth <= 576 || this.innerWidth > 576 && this.innerWidth <= 1180){
      // Cor fonte da Home Arrow
      let colorHomeArrowPassoAPasso1 = document.getElementsByName("colorHomeArrowPassoAPasso1");
      colorHomeArrowPassoAPasso1[0].style.borderTopColor = this.home.colorFonte;

      let colorHomeArrowPassoAPasso2 = document.getElementsByName("colorHomeArrowPassoAPasso2");
      colorHomeArrowPassoAPasso2[0].style.borderTopColor = this.home.colorFonte;

      let colorHomeArrowPassoAPasso3 = document.getElementsByName("colorHomeArrowPassoAPasso3");
      colorHomeArrowPassoAPasso3[0].style.borderTopColor = this.home.colorFonte;

      let colorHomeArrowPassoAPasso4 = document.getElementsByName("colorHomeArrowPassoAPasso4");
      colorHomeArrowPassoAPasso4[0].style.borderTopColor = this.home.colorFonte;

      // Cor fonte da Home Arrow
      colorHomeArrowPassoAPasso1[0].style.borderLeftColor = this.home.colorSite;

      colorHomeArrowPassoAPasso2[0].style.borderLeftColor = this.home.colorSite;

      colorHomeArrowPassoAPasso3[0].style.borderLeftColor = this.home.colorSite;

      colorHomeArrowPassoAPasso4[0].style.borderLeftColor = this.home.colorSite;

    }//Desktop
    else{

       // Cor fonte da Home Arrow
       let colorHomeArrowPassoAPasso1 = document.getElementsByName("colorHomeArrowPassoAPasso1");
     //  if(colorHomeArrowPassoAPasso1 !== undefined && colorHomeArrowPassoAPasso1 !== null && colorHomeArrowPassoAPasso1.length > 0 && colorHomeArrowPassoAPasso1[0] != null && colorHomeArrowPassoAPasso1[0].style !== undefined && colorHomeArrowPassoAPasso1[0].style.borderTopColor !== undefined)
          colorHomeArrowPassoAPasso1[0].style.borderTopColor = this.home.colorSite;

       let colorHomeArrowPassoAPasso2 = document.getElementsByName("colorHomeArrowPassoAPasso2");
     //  if(colorHomeArrowPassoAPasso2 !== undefined && colorHomeArrowPassoAPasso2 !== null && colorHomeArrowPassoAPasso2.length > 0 && colorHomeArrowPassoAPasso2[0] != null && colorHomeArrowPassoAPasso2[0].style !== undefined && colorHomeArrowPassoAPasso2[0].style.borderTopColor !== undefined)
          colorHomeArrowPassoAPasso2[0].style.borderTopColor = this.home.colorSite;

       let colorHomeArrowPassoAPasso3 = document.getElementsByName("colorHomeArrowPassoAPasso3");
      // if(colorHomeArrowPassoAPasso3 !== undefined && colorHomeArrowPassoAPasso3 !== null && colorHomeArrowPassoAPasso3.length > 0 && colorHomeArrowPassoAPasso3[0] != null && colorHomeArrowPassoAPasso3[0].style !== undefined && colorHomeArrowPassoAPasso3[0].style.borderTopColor !== undefined)
          colorHomeArrowPassoAPasso3[0].style.borderTopColor = this.home.colorSite;

       let colorHomeArrowPassoAPasso4 = document.getElementsByName("colorHomeArrowPassoAPasso4");
       colorHomeArrowPassoAPasso4[0].style.borderTopColor = this.home.colorSite;


       colorHomeArrowPassoAPasso1[0].style.borderLeftColor = this.home.colorFonte;

       colorHomeArrowPassoAPasso2[0].style.borderLeftColor = this.home.colorFonte;

       colorHomeArrowPassoAPasso3[0].style.borderLeftColor = this.home.colorFonte;

       colorHomeArrowPassoAPasso4[0].style.borderLeftColor = this.home.colorFonte;
  }

    // Cor fonte da Home Line
    let colorHomeLinePassoAPasso1 = document.getElementsByName("colorHomeLinePassoAPasso1");
    colorHomeLinePassoAPasso1[0].style.borderTopColor = this.home.colorFonte;

    let colorHomeLinePassoAPasso2 = document.getElementsByName("colorHomeLinePassoAPasso2");
    colorHomeLinePassoAPasso2[0].style.borderTopColor = this.home.colorFonte;

    let colorHomeLinePassoAPasso3 = document.getElementsByName("colorHomeLinePassoAPasso3");
    colorHomeLinePassoAPasso3[0].style.borderTopColor = this.home.colorFonte;

    let colorHomeLinePassoAPasso4 = document.getElementsByName("colorHomeLinePassoAPasso4");
    colorHomeLinePassoAPasso4[0].style.borderTopColor = this.home.colorFonte;

  }

 }
