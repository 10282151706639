
     <nav class="navbar navbar-expand-lg position-fixed" name="colorMenu">
        <div class="container-fluid">
            <a class="navbar-brand" routerLink="/{{home.chaveParceiro}}#principal" (click)="forceNavigate('principal')"><img src="{{home.logo}}" loading="lazy"></a>
            <button class="navbar-toggler"
                (click)="isCollapsed = !isCollapsed "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavAltMarkup"
                aria-controls="navbarNavAltMarkup"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <fa-icon [icon]="faClose" *ngIf="!isCollapsed"></fa-icon>
                <fa-icon [icon]="faBarChart" *ngIf="isCollapsed"></fa-icon>
            </button>
            <div class="collapse navbar-collapse" [collapse]="isCollapsed" [isAnimated]="true" id="navbarNavAltMarkup" name="colorMenuMobile">
                <div class="navbar-nav">
                    <a name="colorMenuFontePaginaInicial" class="nav-link active" aria-current="page" routerLink="/{{home.chaveParceiro}}#principal" (click)="forceNavigate('principal')">Página Inicial</a>
                    <a name="colorMenuFonteComoParticipar" class="nav-link" routerLink="/{{home.chaveParceiro}}#comoParticipar" (click)="forceNavigate('comoParticipar')">Como Participar</a>
                    <a name="colorMenuFonteRegulamento" class="nav-link" [routerLink]="[regulamento]" >Regulamento</a>
                    <a name="colorMenuFonteContato" class="nav-link" routerLink="/{{home.chaveParceiro}}#contatos" (click)="forceNavigate('contatos')">Contato</a>
                    <a name="colorMenuFonteSorteio" class="nav-link" routerLink="/{{home.chaveParceiro}}#sorteios" (click)="forceNavigate('sorteios')">Sorteio</a>
                    <a name="colorMenuFonteSegurancaPrivacidade" class="nav-link" href="https://sobreuol.noticias.uol.com.br/normas-de-seguranca-e-privacidade" target="_blank">Segurança e Privacidade</a>
                    <a class="nav-link-end" href="{{home.urlAtivacao}}" target="_blank">Ative seu Passaporte</a>
                </div>
            </div>
        </div>
    </nav>



