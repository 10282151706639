import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Rule } from "@/model/conteudo/rule";
import { SiteRuleService } from "../site-rule.service";
import { DataSourceService } from "./datasource.service";

@Injectable({
  providedIn: 'root'
})

export class SiteRuleDataSourceService extends DataSourceService<Rule> {

  protected objectDataSorce: BehaviorSubject<Rule>;

  protected dataService: SiteRuleService;

  public key:string = '';

  protected listDataSorce: BehaviorSubject<Rule[]>;

  constructor(siteRuleService:SiteRuleService, override router: Router) {
    super(router);
    this.dataService = siteRuleService;
    this.listDataSorce = new BehaviorSubject<Rule[]>(new Array);
    this.objectDataSorce = new BehaviorSubject<Rule>(new Rule);
    this.key=  `${this.keySite}_rule` ;

   }
}
