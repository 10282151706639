import { Home } from "@/model/conteudo/home";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SiteHomeService } from "../site-home.service";
import { DataSourceService } from "./datasource.service";

@Injectable({
  providedIn: 'root'
})

export class SiteHomeDataSourceService extends DataSourceService<Home> {

  protected objectDataSorce: BehaviorSubject<Home>;

  protected dataService: SiteHomeService;

  public key:string = '';

  protected listDataSorce: BehaviorSubject<Home[]>;

  constructor(siteHomeService:SiteHomeService,
              public override router: Router) {
    super( router);
    this.dataService = siteHomeService;
    this.listDataSorce = new BehaviorSubject<Home[]>(new Array);
    this.objectDataSorce = new BehaviorSubject<Home>(new Home);
   }
}
