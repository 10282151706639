<article class="home">
  <section id="principal">
    <div class="banner">
        <img src="{{home.imgLeft}}" class="mt-3">
        <img src="{{home.imgRight}}" class="mt-3">
    </div>
  </section>
    <section id="comoParticipar">
    <div class="tutorial"  name = "colorHome">
        <h1 class="title font-25">Como Participar</h1>
        <p class="font-25 margin-bottom-2">Para participar é muito fácil:</p>
          <div class="row justify-content-xl-evenly tutorialSteps" >
              <div class="col-xl-2 p-3 imgs">
                <img src="./assets/passoapasso_1.png" />

                <p *ngIf="home.chaveParceiro != 'topmoveis'; else _topmoveisP1">
                  Adquira seu passaporte em uma das lojas {{home.nomeParceiro}}. Quanto mais passaportes, mais chances de ganhar.
                </p>

                <ng-template #_topmoveisP1>
                  <p>Adquiriu seu cartão FIT? Ative o código para poder participar do sorteio.</p>
                </ng-template>

              <div class="arrow" name="colorHomeArrowPassoAPasso1"></div>
                <div class="line"  name="colorHomeLinePassoAPasso1"></div>
              </div>
              <div class="col-xl-2 p-3 imgs">
                <img src="./assets/passoapasso_2.png" />

                <p *ngIf="home.chaveParceiro != 'topmoveis'; else _topmoveisP2">
                  Automaticamente você já está concorrendo aos prêmios pela Loteria Federal
                </p>

                <ng-template #_topmoveisP2>
                  <p>Somente ativando o código você terá chances de ganhar.</p>
                </ng-template>

                <div class="arrow" name="colorHomeArrowPassoAPasso2"></div>
                <div class="line"  name="colorHomeLinePassoAPasso2"></div>
              </div>
              <div class="col-xl-2 p-3 imgs">
                <img src="./assets/passoapasso_3.png" />
                <p>Acesse a página {{home.urlAtivacao}} para estudar</p>
                <div class="arrow" name="colorHomeArrowPassoAPasso3"></div>
                <div class="line"  name="colorHomeLinePassoAPasso3"></div>
              </div>
              <div class="col-xl-2 p-3 imgs">
                <img src="./assets/passoapasso_4.png" />
                <p>Insira seus dados e clique em acessar conteúdo.</p>
                <div class="arrow" name="colorHomeArrowPassoAPasso4"></div>
                <div class="line"  name="colorHomeLinePassoAPasso4"></div>
              </div>
              <div class="col-xl-2 p-3 imgs">
                <img src="./assets/passoapasso_5.png" />
                <p>Já está cadastrado, adquiriu outros passaportes e deseja estudar? Acesse o site {{home.urlAtivacao}}, clique em Login e siga os passos para começar a estudar.</p>
              </div>
        </div>
    </div>
  </section>
  <section id="contatos">
    <div class="contacts">
        <h1 class="title font-25">Contato</h1>
        <div class="row align-items-center align-items-xl-center ">
            <div class="column d-flex col-xl-4 justify-content-xl-end">
                <img src="./assets/email_icon.png" alt="email" />
                <span class="font-15 margin-left-1">sac@crescabrasil.com.br</span>
            </div>
            <div class="column  d-flex col-xl-4">
                <img src="./assets/whatsapp_icon.png" alt="whatsapp" />
                <div class="d-flex column-wts flex-column align-items-start align-items-xl-center ms-3 ms-xl-0  " >
                  <span class="text-xl-center">(11) 99497-3716 - <a target="_blank" href="https://api.whatsapp.com/send?phone=5511994973716&text=Ol%C3%A1,%20tenho%20d%C3%BAvidas%20sobre%20meu%20curso"> Clique Aqui</a></span>
                  <p class="text-start text-xl-center">Atendimento de segunda à sexta - A sua solicitação será atentida em até 48h úteis</p>
                </div>
            </div>
            <div class="column  d-flex col-xl-4 justify-content-xl-start">
                <img src="./assets/faq_icon.png" alt="FAQ"/>
                <a target="_blank" href="https://www.crescabrasil.com.br/institucional#perguntas" class="font-15 margin-left-1" >PERGUNTAS FREQUENTES</a>
            </div>
        </div>
      </div>
    </section>
    <section id="sorteios">
      <div class="informations" *ngIf="home.dataProximoSorteio != null">
        <p class="sorteio">SORTEIO</p>
          <p class="proximosorteio">Próximo sorteio: {{home.dataProximoSorteio | date:"dd/MM/yyyy"}}</p>
          <p class="has-text-centered margin-top-3"> </p>
       </div>
       <div class="informations" >
        <a class="button" routerLink="/{{home.chaveParceiro}}/rules">Acesse o Regulamento</a>
       </div>
       <div class="winners" *ngFor="let sorteio of home.sorteios">
        <p class="sorteio">CONFIRA OS GANHADORES DAS PROMOÇÕES ANTERIORES:</p>
         <p class="sorteio">Sorteio {{sorteio.dataSorteiro | date:"dd/MM/yyyy"}}</p>
        <ul id="winners" *ngFor="let ganhador of sorteio.ganhadores ">
          <li class="">{{ganhador.nomeGanhador}}</li>
        </ul>
     </div>
    </section>
</article>
