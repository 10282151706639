import { Sorteio } from "./sorteio";

export class Rule{
    nomeParceiro: string = '';
    chaveParceiro: string = '';
    logo: string = '';
    imgLeft: string = '';
    imgRight: string = '';
    imgRegulamento: string = '';
    dataProximoSorteio?: Date | null ;
    textoRodape: string = '';
    arquivoRegulamento: string = '';
    urlAtivacao: string = '';
    colorFonte: string = '';
    colorSite: string = '';
    sorteios: Sorteio[] = [] ;
}


