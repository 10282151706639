import { Component,  EventEmitter,  HostListener,  Input, OnInit, Output } from '@angular/core';
import { faBarChart, faClose, faThList } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeUrl, SafeValue } from '@angular/platform-browser';
import { Home } from '@/model/conteudo/home';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {

  isCollapsed = true;
  faClose = faClose;
  faBarChart = faBarChart;

  imageLogo:SafeUrl='';
  contato:SafeUrl='';
  regulamento:SafeUrl='';


  @Input()
  public home:Home = new Home();

  innerWidth: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {



  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.carregarCorFonteSite();
  }

  forceNavigate(name: string) {

    setTimeout(() => {
        this.router.navigate([`/${this.home.chaveParceiro}`], { fragment: name });
    }, 600);

  }

  public carregar(): void{


    this.carregarCorSite();
    this.carregarCorSite();

    const key = String(this.route.snapshot.paramMap.get("key")).replace("#principal","").replace("#comoParticipar","").replace("#contatos","").replace("#sorteios","");
    this.home.chaveParceiro = this.home.chaveParceiro != null && this.home.chaveParceiro != '' ? this.home.chaveParceiro : key;
    this.regulamento = `/${this.home.chaveParceiro}/rules`;
  }

  carregarCorSite(){

    // Cor do Site Menu
    let colorMenu = document.getElementsByName("colorMenu");
    colorMenu[0].style.background = this.home.colorSite;

    let colorMenuMobile = document.getElementsByName("colorMenuMobile");
    colorMenuMobile[0].style.background = this.home.colorSite;

  }

  carregarCorFonteSite(){

    // Cor fonte Menu
    let colorMenuFontePaginaInicial = document.getElementsByName("colorMenuFontePaginaInicial");
    colorMenuFontePaginaInicial[0].style.color = this.home.colorFonte;

    let colorMenuFonteComoParticipar = document.getElementsByName("colorMenuFonteComoParticipar");
    colorMenuFonteComoParticipar[0].style.color = this.home.colorFonte;

    let colorMenuFontePaginaInicialRegulamento = document.getElementsByName("colorMenuFonteRegulamento");
    colorMenuFontePaginaInicialRegulamento[0].style.color = this.home.colorFonte;

    let colorMenuFonteContato = document.getElementsByName("colorMenuFonteContato");
    colorMenuFonteContato[0].style.color = this.home.colorFonte;

    let colorMenuFonteSorteio = document.getElementsByName("colorMenuFonteSorteio");
    colorMenuFonteSorteio[0].style.color = this.home.colorFonte;

    let colorMenuFonteSegurancaPrivacidade = document.getElementsByName("colorMenuFonteSegurancaPrivacidade");
    colorMenuFonteSegurancaPrivacidade[0].style.color = this.home.colorFonte;

  }

}
