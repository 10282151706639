import { Sorteio } from "./sorteio";

export class Home{
    nomeParceiro: string = '';
    chaveParceiro: string = '';
    logo: string = '';
    imgLeft: string = '';
    imgRight: string = '';
    imgRegulamento: string = '';
    dataProximoSorteio?: Date | null ;
    textoRodape: string = '';
    arquivoRegulamento: string = '';
    urlAtivacao: string = '';
    colorFonte: string = '';
    colorSite: string = '';
    sorteios: Sorteio[] = [] ;
}
export class RuleHome extends Home{

}

export class HomeRule extends Home{

}

export class MainHome extends Home{

}



