import { HomeRule } from "@/model/conteudo/home";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { SiteRuleService } from "../site-rule.service";
import { DataSourceService } from "./datasource.service";

@Injectable({
  providedIn: 'root'
})

export class SiteHomeRuleDataSourceService extends DataSourceService<HomeRule> {

  protected objectDataSorce: BehaviorSubject<HomeRule>;

  protected dataService: SiteRuleService;

  public key:string = '';

  protected listDataSorce: BehaviorSubject<HomeRule[]>;

  constructor(siteHomeRuleService:SiteRuleService,
              public override router: Router) {
    super( router);
    this.dataService = siteHomeRuleService;
    this.listDataSorce = new BehaviorSubject<HomeRule[]>(new Array);
    this.objectDataSorce = new BehaviorSubject<HomeRule>(new HomeRule);
   }
}
