import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './modules/main/header/header.component';
import { FooterComponent } from './modules/main/footer/footer.component';
import { RulesComponent } from './components/rules/rules.component';
import { HomeComponent } from './pages/home/home.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClientModule } from '@angular/common/http';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ErrorComponent } from './components/error/error.component';
import { SiteHomeService } from './services/site-home.service';
import { SiteRuleService } from './services/site-rule.service';
import { MainComponent } from './modules/main/main.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    RulesComponent,
    HomeComponent,
    NotFoundComponent,
    ErrorComponent,
    MainComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    FontAwesomeModule,
    HttpClientModule
  ],
  providers: [SiteHomeService, SiteRuleService],
  bootstrap: [AppComponent]
})

export class AppModule { }
